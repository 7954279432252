import { useState, useEffect } from 'react'
import throttle from 'lodash/throttle'
// Components
import Icon from '@/components/Icon/Icon'

// Context
import { useMobileSearchContext } from '@/components/context/MobileSearchContext'
import { useRouter } from 'next/router'
import useDebounce from '@/hooks/useDebounce'
import { server } from '@/config/server'
import dynamic from 'next/dynamic'

const DynamicMobileSearchSuggestion = dynamic(
  () =>
    import('@/components/Header/Menu/MobileMenu/MobileSearchSuggestion').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: false,
  }
)

const MobileSearch = ({
  autoFocus = false,
  id,
  isOpenModal,
  resetZindex = false,
  searchPlaceholderText,
  tabIndex = 0,
  customer,
  placement = 'header',
  modalViewHandler,
}) => {
  const { mobileSearch: search, setMobileSearch: setSearch } = useMobileSearchContext()
  const [searchTerm, setSearchTerm] = useState(search.requestQuery || '')
  const [loading, setLoading] = useState(false)
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const router = useRouter()

  const searchRequest = (value) => {
    if (placement == 'header') {
      if (value) {
        setSearch((prev) => ({ ...prev, requestQuery: value }))
      } else {
        setSearch((prev) => ({ ...prev, requestQuery: false }))
      }
    }
  }

  const clearSearch = () => {
    setSearch({
      requestQuery: false,
      request: false,
      loading: false,
      data: {},
      categories: [],
      suggest: null,
      merchandising: null,
    })
    //setSearchTerm('')
  }

  const onSearchSuggestionRequest = async (searchTerm, signal) => {
    if (!searchTerm || searchTerm.length < 2)
      return setSearch((prev) => ({ ...prev, suggest: null }))

    const response = await fetch(`${server}/api/searchspring/suggestion`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: searchTerm,
      }),
      signal: signal,
    })
    const data = await response.json()
    if (data) setSearch((prev) => ({ ...prev, suggest: data }))
    return data
  }

  useEffect(() => {
    let abortController = new AbortController()

    if (debouncedSearchTerm) {
      onSearchSuggestionRequest(debouncedSearchTerm, abortController.signal)
        .then(() => {
          searchRequest(debouncedSearchTerm)
          setLoading(false)
        })
        .catch((e) => {
          clearSearch()
        })
    } else {
      clearSearch()
    }

    return () => {
      clearSearch()

      abortController.abort()
    }
  }, [debouncedSearchTerm])

  useEffect(() => {
    if (searchTerm?.length < 3) {
      clearSearch()
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [searchTerm])

  const suggestedResult = throttle(() => {
    if (search.suggest && search.suggest.suggested.text) {
      let suggestedResult = search.suggest.suggested.text.toLowerCase()
      let suggestedQuery = search.suggest.query.toLowerCase()
      if (suggestedResult && suggestedResult.includes(suggestedQuery)) {
        return suggestedResult.replace(suggestedQuery, '')
      }
    }
    return null
  }, 500)

  const enterPressed = async (event) => {
    try {
      const queryLength = event.target.value.length
      const searchQuery = event.target.value
      if (queryLength > 1 && event.key === 'Enter') {
        setSearch((prev) => ({ ...prev, loading: true }))
        const response = await fetch(`${server}/api/searchspring/autocomplete`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            q: searchQuery,
          }),
        })

        if (typeof window !== undefined && window.dataLayer) {
          dataLayer.push({
            event: 'view_search_results',
            search_term: searchQuery,
          })
        }
        const searchData = await response?.json()

        clearSearch()
        setSearchTerm('')

        if (placement == 'nav') {
          modalViewHandler()
        }

        event.target.blur()

        if (searchData?.merchandising?.redirect) {
          router.push(searchData?.merchandising?.redirect)
        } else {
          router.push(`/search?q=${searchQuery}`)
        }
      }
    } catch (error) {
      console.log('mobile search enterPressed')
    }
  }

  return (
    <>
      <div
        className={`pb-[10px] ${
          isOpenModal ? 'pt-[10px] border-b border-gray-200' : ' border-transparent'
        } `}
        style={{
          background: isOpenModal ? 'white' : '#111111',
        }}
      >
        <div
          className={`py-[12px] ${
            isOpenModal ? '' : 'px-[20px]'
          } container w-full rounded-sm bg-white `}
        >
          <div className="flex w-full items-center bg-transparent relative">
            <Icon
              className={`cursor-pointer text-black fill-current`}
              viewBox={`0 0 21 22`}
              size={18}
              icon="search2"
            />
            <input
              aria-label="search"
              autoComplete="off"
              // autoFocus={autoFocus}
              className="search-input w-full ml-2 outline-none text-base md:text-sm  bg-transparent focus:outline-none"
              id={id}
              name="search"
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={(e) => enterPressed(e)}
              placeholder={searchPlaceholderText || 'What are you looking for?'}
              style={{ zIndex: resetZindex ? '0' : '1' }}
              tabIndex={tabIndex}
              type="search"
              value={searchTerm}
            />

            {loading && placement == 'header' ? (
              <div className="icon-container">
                <i className="loader"></i>
              </div>
            ) : null}

            {searchTerm && search.suggest && search.suggest.suggested ? (
              <div className="suggestion z-0" style={{ zIndex: '0' }}>
                <span className="typed">{searchTerm}</span>
                <span className="suggested">{search.suggest && suggestedResult()}</span>
              </div>
            ) : null}

            {search.requestQuery ? (
              <div
                className="cursor-pointer search-close"
                onClick={() => {
                  clearSearch()
                  setSearchTerm('')
                }}
              >
                <Icon className="search-close" viewBox={`0 0 26 26`} size={12} icon="close" />
              </div>
            ) : null}
          </div>
        </div>
        {search?.requestQuery && search?.requestQuery?.length > 2 && placement == 'header' && (
          <DynamicMobileSearchSuggestion
            q={search.requestQuery}
            customer={customer}
            searchRequest={searchRequest}
            clearSearch={clearSearch}
            search={search}
          />
        )}
      </div>

      <style jsx>{`
        .search-input {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.02em;
          color: #4c4442;
        }
        .suggestion {
          position: absolute;
          left: 25px;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.02em;
          width: 260px;
        }
        .suggestion .typed {
          opacity: 0;
        }
        .suggestion .suggested {
          font-weight: bold;
        }
        #mobile-search-outer::-webkit-search-cancel-button{
          display:none;
        }
        }
      `}</style>
    </>
  )
}

export default MobileSearch
